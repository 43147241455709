import {
  Banner,
  Coupon,
  Price,
  StripeBillingInfo,
  SubscriptionPlanInterval,
  SubscriptionPlanStripe,
  Ticket,
} from 'generated/graphql';
import { pushToGoogleTagManager } from 'src/tracking/gtm';
import {
  CurrencyCode,
  defaultCurrencyCode,
  isCurrencyCode,
  zeroDecimalCurrencies,
} from 'src/utilities/currency-code-helpers';
import { getPrice } from 'src/utilities/price-helpers';

const brandName = 'STAGE+';

/**
 * Trackable names for the subscription plans
 */
const subscriptionNames: Record<SubscriptionPlanInterval, string> = {
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
  DAYS_7: '7 Days',
};

// All Ecommerce tracking events should be defined here

/**
 * Convert a currency number to the trackable format.
 * .e.g. '14.90' for 'EUR' and '1490' for 'JPY'
 */
function formatPriceNumber(amount: number, currency: CurrencyCode): string {
  const decimalAmount = zeroDecimalCurrencies.includes(currency) ? amount.toString() : (amount / 100).toFixed(2);
  return decimalAmount;
}

// promotion event types
type PromotionEvent = 'EECpromotionImpression' | 'EECpromotionClick';
export type PromotionEventPayload = { eventName: PromotionEvent; banner: Banner };

// subscription event types
export type SubscriptionImpressionEventPayload = {
  eventName: 'SubscriptionImpression';
  subscriptions: SubscriptionPlanStripe[];
  currency: CurrencyCode;
  listName: string;
};

// post-subscription form modal, e.g. add you name and address
export type PostSubscriptionDefaultModalPayload = {
  eventName: 'PostSubscriptionFormImpression' | 'PostSubscriptionFormSubmit';
  cta?: string;
};

export type CouponEventPayload = {
  eventName: 'CouponRedeemed';
  coupon: Coupon;
};

type SubscriptionClickEventPayload = {
  eventName: 'SubscriptionClick';
  subscription: SubscriptionPlanStripe;
  price: Price;
  listName: string;
};
type SubscriptionCheckoutEventPayload = {
  eventName: 'SubscriptionCheckout';
  subscription: SubscriptionPlanStripe;
  price: Price;
  step: number;
  option?: string;
};
type SubscriptionOrderTrackable = {
  id: string;
  amount: number;
  affiliation: string;
  couponCode: string;
  couponDiscount: string;
  paymentMethodType: string;
  ticketType: Ticket['ticketType'];
  isTrial: Ticket['isTrial'];
};
type SubscriptionPurchaseEventPayload = {
  eventName: 'SubscriptionPurchase';
  subscription: SubscriptionPlanStripe;
  price: Price;
  order: SubscriptionOrderTrackable;
};
export type SubscriptionEventPayload =
  | SubscriptionClickEventPayload
  | SubscriptionCheckoutEventPayload
  | SubscriptionPurchaseEventPayload;
export type SubscriptionCanceledEventPayload = {
  eventName: 'SubscriptionCanceled';
  ticket: Pick<Ticket, 'interval' | 'isTrial'>;
  stripeBillingInfo: Pick<StripeBillingInfo, 'amount' | 'currency'>;
};

/**
 * Track impressions and clicks of internal promotions, e.g. banners in analytics and GTM
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter4.1
 */
export const trackPromotion = ({ eventName, banner }: PromotionEventPayload) => {
  pushToGoogleTagManager({
    event: eventName,
    ecommerce: {
      [eventName === 'EECpromotionImpression' ? 'promoView' : 'promoClick']: {
        promotions: [
          {
            id: banner.link,
            name: banner.title,
            creative: banner.pictures[0]?.url,
            position: '1',
          },
        ],
      },
    },
  });
};

/**
 * Track impressions of subscriptions in analytics and GTM
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter4.2.1
 */
export const trackSubscriptionImpression = ({
  subscriptions,
  currency,
  listName: list,
}: SubscriptionImpressionEventPayload) => {
  pushToGoogleTagManager({
    event: 'product_impression',
    ecommerce: {
      currencyCode: currency.toUpperCase(),
      impressions: subscriptions.map((subscription, index) => ({
        name: subscriptionNames[subscription.interval],
        id: subscriptionNames[subscription.interval],
        price: formatPriceNumber(getPrice(subscription, currency).amount, currency),
        brand: brandName,
        category: 'Subscription',
        variant: subscriptionNames[subscription.interval],
        list: list,
        position: (index + 1).toString(),
      })),
    },
  });
};

/**
 * After a subscription is confirmed,
 * sometimes the user is asked to fill out a form or to add more information.
 */
export const trackPostSubscriptionForm = ({ eventName, cta }: PostSubscriptionDefaultModalPayload) => {
  const isImpression = eventName === 'PostSubscriptionFormImpression';
  const modalVersion = 'Default';
  pushToGoogleTagManager(
    isImpression
      ? {
          event: 'Generic Event',
          event_name: 'post_subscription_form_impression',
          post_subscription_form_impression: {
            display_context: 'Modal',
            modal_version: modalVersion,
          },
        }
      : {
          event: 'Generic Event',
          event_name: 'post_subscription_form_submitted',
          post_subscription_form_submitted: {
            display_context: 'Modal',
            modal_version: modalVersion,
            cta: cta || '',
          },
        },
  );
};

/**
 * Track the redeemed Coupon codes in analytics and GTM
 * @param coupon the coupon that was redeemed
 */
export const trackCoupon = ({ coupon }: CouponEventPayload) => {
  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: 'voucher_redeemed',
    voucher_redeemed: {
      voucher_code: coupon.promotionCode,
      voucher_value: String(coupon.percentOff),
    },
  });
};

/**
 * Track clicks, checkout and purchase of subscriptions in analytics and GTM
 * based on the spec from:
 * https://guide.trakken.de/5f807081e90fd60009b13d1b/published/60829afcb4ea24000aab8185#chapter4.2.2
 */
export const trackSubscriptionInteraction = ({ eventName, subscription, price, ...rest }: SubscriptionEventPayload) => {
  // convert event type to GTM event type
  const eventNameMap: Record<SubscriptionEventPayload['eventName'], string> = {
    SubscriptionClick: 'product_click',
    SubscriptionCheckout: 'saw_payment_page',
    SubscriptionPurchase: 'subscription_confirmed',
  };

  const ecommerceStep: Record<SubscriptionEventPayload['eventName'], string> = {
    SubscriptionClick: 'click',
    SubscriptionCheckout: 'checkout',
    SubscriptionPurchase: 'purchase',
  };

  const currencyCode = isCurrencyCode(price.currency) ? price.currency : defaultCurrencyCode;
  const subscriptionName = subscriptionNames[subscription.interval];
  // get the order object if the event is a purchase
  const order = 'order' in rest ? rest.order : undefined;

  // fill the action field according to the event type
  const getActionFieldValue = () => {
    // get the checkout step if the event is a checkout
    const checkoutStep = 'step' in rest ? rest.step : undefined;
    // get where the subscription was listed before the click
    const listName = 'listName' in rest ? rest.listName : undefined;

    switch (eventName) {
      case 'SubscriptionClick': {
        return { list: listName };
      }
      case 'SubscriptionCheckout': {
        return { step: checkoutStep };
      }
      case 'SubscriptionPurchase': {
        return {
          id: order?.id,
          affiliation: order?.affiliation,
          revenue: formatPriceNumber(price.amount, currencyCode),
          tax: '0.00',
          shipping: '0.00',
          coupon: order?.couponCode,
          coupon_value: order?.couponDiscount,
          payment_method_type: order?.paymentMethodType,
        };
      }
      default: {
        return undefined;
      }
    }
  };

  pushToGoogleTagManager({
    event: eventNameMap[eventName],
    ecommerce: {
      currencyCode: price.currency.toUpperCase(),
      [ecommerceStep[eventName]]: {
        actionField: getActionFieldValue(),
        products: [
          {
            name: subscriptionName,
            id: subscriptionName,
            price: formatPriceNumber(price.amount, currencyCode),
            brand: brandName,
            category: 'Subscription',
            variant: subscriptionName,
            quantity: '1',
          },
        ],
      },
    },
  });

  // the event name differs for different kinds of subscription purchase,
  //  depending on the ticket type and trial status
  const getCapiPurchaseEventName = () => {
    if (eventName !== 'SubscriptionPurchase' || !order) {
      return undefined;
    } else if (order?.ticketType === 'ONE_TIME_PURCHASE') {
      return 'OneTimePurchased';
    } else if (order?.isTrial) {
      return 'TrialStarted';
    } else {
      return 'Subscribed';
    }
  };
  // get the CAPI tracking name for the type of purchase
  const capiPurchaseEventName = getCapiPurchaseEventName();

  // if the event is not a purchase, don't track it
  if (!capiPurchaseEventName) {
    return;
  }

  // track the modified subscription purchase event in GTM
  pushToGoogleTagManager({
    event: capiPurchaseEventName,
    ecommerce: {
      transaction_id: order?.id,
      value: formatPriceNumber(price.amount, currencyCode),
      currency: price.currency.toUpperCase(),
      coupon: order?.couponCode || '',
      trial: order?.isTrial || false,
      purchase_type: order?.ticketType,
      items: [
        {
          item_id: price.id,
          item_name: subscriptionName,
          discount: order?.couponDiscount || '0',
          item_brand: 'Stage+',
          // for now, hard-coded to 'tickets',
          // as the subscription is always listed in the Subscribe page under the url '/tickets'
          item_list_id: 'tickets',
          item_list_name: 'Subscribe', // same as above
          item_category: order?.ticketType,
          item_variant: order?.ticketType,
          price: formatPriceNumber(price.amountUndiscounted, currencyCode),
          quantity: 1,
        },
      ],
    },
  });
};

/**
 * Tracks the cancellation of a subscription in GTM
 */
export const trackSubscriptionCanceled = ({ stripeBillingInfo, ticket }: SubscriptionCanceledEventPayload) => {
  pushToGoogleTagManager({
    event: 'Generic Event',
    event_name: 'subscription_canceled',
    subscription_canceled: {
      product_type: subscriptionNames[ticket.interval],
      product_price: formatPriceNumber(
        Number.parseInt(stripeBillingInfo.amount, 10),
        isCurrencyCode(stripeBillingInfo.currency) ? stripeBillingInfo.currency : defaultCurrencyCode,
      ),
      trial_period: ticket.isTrial,
    },
  });
};
